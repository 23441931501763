export default {
    defaultPath: '/auth/signin',
    basename: '', // only at build time to set, like //next/react/
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'header-blue', // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
    rtlLayout: false,
    navFixedLayout: false,
    headerFixedLayout: false,
    boxLayout: false,
    googleTranslateAPIKey : "AIzaSyAtFU_1SeEgelZ9DlfYg0Pl_ErU38GNgKc",

    //apiURL: 'https://api.cllr.pegotec.pro',
    // apiURL: 'https://api.cllr.pegotec.net',
    // apiURL: 'http://127.0.0.1:8000',
    apiURL: 'https://api.our-rights.pegotec.dev',
    // apiURL: 'https://api.our-rights-cambodia.org'    ,
};
